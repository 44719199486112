// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/img_search.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "input[data-v-5f0479c6] {\n  border: none;\n  appearance: none;\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  outline: none;\n  background-color: #f3f5fa;\n  color: #303132;\n  width: 100%;\n  font-size: 16px;\n}\n.search-container[data-v-5f0479c6] {\n  display: flex;\n  margin: 10px 0;\n  border: 1px solid transparent;\n  padding: 10px;\n  border-radius: 3px;\n  background-color: #f3f5fa;\n  border-color: #aaadb3;\n}\n.search-container-focused[data-v-5f0479c6] {\n    border-color: #00adee;\n}\n.search-icon[data-v-5f0479c6] {\n  height: 20px;\n  width: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 4px;\n}\n", ""]);
// Exports
module.exports = exports;
