<template>
    <div
        class="search-container"
        :class="{
            'search-container-focused': isFocused
        }"
    >
        <div class="search-icon"></div>
        <input
            class="input-box"
            type="text"
            :placeholder="placeholder"
            v-model="inputValue"
            @focus="isFocused = true"
            @blur="isFocused = false"
        />
    </div>
</template>

<script>
export default {
    name: 'TextboxSearch',
    emits: ['update:modelValue', 'searched-data'],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        dataList: {
            type: Array,
            default: () => []
        },
        searchConfigs: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isFocused: false,
            inputValue: this.modelValue
        }
    },
    watch: {
        isLoading() {
            this.inputValue = ''
        },
        inputValue(newVal) {
            this.$emit('update:modelValue', newVal)
            this.searchData(newVal)
        },
        modelValue(val) {
            this.inputValue = val
        }
    },
    methods: {
        /**
         * Data關鍵字搜尋 emit篩選結果
         * @param {string} keyword 關鍵字
         */
        searchData: function (keyword) {
            const trimmedKeyword = keyword.trim()
            if (!trimmedKeyword) {
                this.$emit('searched-data', this.dataList)
                return
            }
            const results = this.dataList.filter((data) => {
                return this.dataHasKeyword(
                    data,
                    this.searchConfigs,
                    trimmedKeyword
                )
            })
            this.$emit('searched-data', results)
        },
        /**
         * 判斷data中任一key中是否含有關鍵字
         * @param {Object} data 被篩選的data
         * @param {Object} searchConfigs 需做篩選的keys設定
         * @param {String} trimmedKeyword 關鍵字
         * @returns {boolean} data是否包含關鍵字
         */
        dataHasKeyword: function (data, searchConfigs, trimmedKeyword) {
            return Object.keys(searchConfigs).some((key) => {
                const keyConfig = searchConfigs[key]
                let dataValue = ''
                if (keyConfig.type === 'group') {
                    // 將group中多種value連接組成一個完整字串內容
                    dataValue = this.conposeContent(
                        data,
                        keyConfig.subDataConfig
                    )
                } else {
                    dataValue = data[key]
                    dataValue = this.valueFormat(dataValue, keyConfig)
                }
                if (dataValue === null) return false
                // console.log(dataValue)
                // 根據不同類型的key
                switch (keyConfig.type) {
                    case 'boolean': {
                        const mappingStr = keyConfig.valueMapping[dataValue]
                        return mappingStr.includes(trimmedKeyword)
                    }
                    case 'array': {
                        return dataValue.some((subData) => {
                            return Object.keys(keyConfig.subDataConfig).some(
                                (key) => {
                                    return subData[key].includes(trimmedKeyword)
                                }
                            )
                        })
                    }
                    default:
                        return dataValue.includes(trimmedKeyword)
                }
            })
        },
        /**
         * 將group中多種value連接組成一個完整文字內容
         * @param {Object} data 被篩選的data
         * @param {Object} searchConfigs 需做篩選的keys設定
         * @returns {string} 完整文字內容
         */
        conposeContent: function (data, searchConfigs) {
            let result = ''
            Object.entries(searchConfigs).forEach(([key, keyConfig]) => {
                let dataValue = data[key]
                if (dataValue !== null) {
                    dataValue = this.valueFormat(dataValue, keyConfig)
                    result += dataValue
                }
            })
            return result
        },
        /**
         * data value加上prefix, suffix內容
         * @param {string} value 原始value
         * @param {Object} keyConfig key設定
         * @returns {string} format後內容
         */
        valueFormat: function (value, keyConfig) {
            if (value === null) return null
            if ('prefix' in keyConfig.format) {
                value = `${keyConfig.format.prefix}${value}`
            }
            if ('suffix' in keyConfig.format) {
                value = `${value}${keyConfig.format.suffix}`
            }
            return value
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    background-color: $primary-grey;
    color: $seventh-black;
    width: 100%;
    font-size: 16px;
}
.search-container {
    display: flex;
    margin: 10px 0;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 3px;
    background-color: $primary-grey;
    border-color: $ninth-grey;
    &-focused {
        border-color: $fourth-blue;
    }
}
.search-icon {
    height: 20px;
    width: 20px;
    background-image: url('~@/assets/images/img_search.svg');
    margin-right: 4px;
}
</style>
